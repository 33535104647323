{
    "titles": {
        "trending": "Trending",
        "login": "Login",
        "register": "Register",
        "feed": "Feed",
        "preferences": "Preferences",
        "history": "History",
        "subscriptions": "Subscriptions",
        "playlists": "Playlists",
        "account": "Account",
        "instance": "Instance",
        "player": "Player",
        "livestreams": "Livestreams",
        "channels": "Channels",
        "bookmarks": "Bookmarks",
        "channel_groups": "Channel groups",
        "dearrow": "DeArrow"
    },
    "player": {
        "watch_on": "View on {0}",
        "failed": "Failed with error code {0}, see logs for more info"
    },
    "actions": {
        "subscribe": "Subscribe",
        "unsubscribe": "Unsubscribe",
        "view_subscriptions": "View Subscriptions",
        "sort_by": "Sort by:",
        "most_recent": "Most Recent",
        "least_recent": "Least Recent",
        "channel_name_asc": "Channel Name (A-Z)",
        "channel_name_desc": "Channel Name (Z-A)",
        "back": "Back",
        "uses_api_from": "Uses the API from ",
        "instances_not_shown": "Public instances that are not shown here are currently unavailable.",
        "enable_sponsorblock": "Enable Sponsorblock",
        "skip_button_only": "Show skip button",
        "skip_automatically": "Automatically",
        "skip_sponsors": "Skip Sponsors",
        "skip_intro": "Skip Intermission/Intro Animation",
        "skip_outro": "Skip Endcards/Credits",
        "skip_preview": "Skip Preview/Recap",
        "skip_interaction": "Skip Interaction Reminder (Subscribe)",
        "skip_self_promo": "Skip Unpaid/Self Promotion",
        "skip_non_music": "Skip Music: Non-Music Section",
        "skip_highlight": "Skip Highlight",
        "skip_filler_tangent": "Skip Filler Tangent",
        "show_markers": "Show Markers on Player",
        "min_segment_length": "Minimum Segment Length (in seconds)",
        "skip_segment": "Skip Segment",
        "enable_dearrow": "Enable DeArrow",
        "theme": "Theme",
        "auto": "Auto",
        "dark": "Dark",
        "light": "Light",
        "autoplay_video": "Autoplay Video",
        "autoplay_next_countdown": "Default Countdown until next video (in seconds)",
        "audio_only": "Audio Only",
        "default_quality": "Default Quality",
        "buffering_goal": "Buffering Goal (in seconds)",
        "country_selection": "Country",
        "default_homepage": "Default Homepage",
        "minimize_comments_default": "Minimize Comments by default",
        "minimize_description_default": "Minimize Description by default",
        "store_watch_history": "Store Watch History",
        "language_selection": "Language",
        "instances_list": "Instances List",
        "enabled_codecs": "Enabled Codecs (Multiple)",
        "instance_selection": "Instance",
        "show_more": "Show More",
        "yes": "Yes",
        "no": "No",
        "export_to_json": "Export to JSON",
        "import_from_json": "Import from JSON",
        "import_from_json_csv": "Import from JSON/CSV",
        "loop_this_video": "Loop this Video",
        "auto_play_next_video": "Auto Play next Video",
        "auto_display_captions": "Auto Display Captions",
        "donations": "Development donations",
        "minimize_comments": "Minimize Comments",
        "show_comments": "Show Comments",
        "minimize_description": "Minimize Description",
        "show_description": "Show Description",
        "minimize_recommendations": "Minimize Recommendations",
        "show_recommendations": "Show Recommendations",
        "disable_lbry": "Disable LBRY for Streaming",
        "enable_lbry_proxy": "Enable Proxy for LBRY",
        "view_ssl_score": "View SSL Score",
        "search": "Search (Ctrl+K)",
        "filter": "Filter",
        "loading": "Loading...",
        "clear_history": "Clear History",
        "hide_replies": "Hide Replies",
        "load_more_replies": "Load more Replies",
        "add_to_playlist": "Add to playlist",
        "remove_from_playlist": "Remove from playlist",
        "delete_playlist_video_confirm": "Remove video from playlist?",
        "create_playlist": "Create Playlist",
        "delete_playlist": "Delete Playlist",
        "select_playlist": "Select a Playlist",
        "delete_playlist_confirm": "Delete this playlist?",
        "please_select_playlist": "Please select a playlist",
        "delete_account": "Delete Account",
        "logout": "Logout from this device",
        "minimize_recommendations_default": "Minimize Recommendations by default",
        "minimize_chapters_default": "Minimize Chapters by default",
        "chapters_layout_mobile": "Chapters Layout On Mobile",
        "show_watch_on_youtube": "Show Watch on YouTube button",
        "invalidate_session": "Logout all devices",
        "different_auth_instance": "Use a different instance for authentication",
        "instance_auth_selection": "Authentication Instance",
        "clone_playlist": "Clone Playlist",
        "clone_playlist_success": "Successfully cloned!",
        "download_as_txt": "Download as .txt",
        "reset_preferences": "Reset preferences",
        "confirm_reset_preferences": "Are you sure you want to reset your preferences?",
        "backup_preferences": "Backup preferences",
        "restore_preferences": "Restore preferences",
        "back_to_home": "Back to home",
        "edit_playlist": "Edit playlist",
        "playlist_name": "Playlist name",
        "playlist_description": "Playlist description",
        "share": "Share",
        "with_timecode": "Share with time code",
        "piped_link": "Piped link",
        "follow_link": "Follow link",
        "copy_link": "Copy link",
        "time_code": "Time code (in seconds)",
        "show_chapters": "Chapters",
        "store_search_history": "Store Search History",
        "hide_watched": "Hide watched videos in the feed",
        "documentation": "Documentation",
        "status_page": "Status",
        "source_code": "Source code",
        "instance_donations": "Instance donations",
        "instance_privacy_policy": "Privacy Policy",
        "reply_count": "{count} replies",
        "no_valid_playlists": "The file doesn't contain valid playlists!",
        "with_playlist": "Share with playlist",
        "bookmark_playlist": "Bookmark",
        "playlist_bookmarked": "Bookmarked",
        "dismiss": "Dismiss",
        "show_less": "Show less",
        "create_group": "Create group",
        "group_name": "Group name",
        "cancel": "Cancel",
        "okay": "Okay",
        "show_search_suggestions": "Show search suggestions",
        "delete_automatically": "Delete automatically after",
        "generate_qrcode": "Generate QR Code",
        "download_frame": "Download frame",
        "add_to_group": "Add to group",
        "concurrent_prefetch_limit": "Concurrent Stream Prefetch Limit"
    },
    "comment": {
        "pinned_by": "Pinned by {author}",
        "disabled": "Comments are disabled by the uploader.",
        "loading": "Loading comments...",
        "user_disabled": "Comments are disabled in the settings."
    },
    "preferences": {
        "instance_name": "Instance Name",
        "instance_locations": "Instance Locations",
        "has_cdn": "Has CDN?",
        "registered_users": "Registered Users",
        "version": "Version",
        "up_to_date": "Up to date?",
        "ssl_score": "SSL Score",
        "uptime_30d": "Uptime (30d)"
    },
    "login": {
        "username": "Username",
        "password": "Password",
        "password_confirm": "Confirm password",
        "passwords_incorrect": "Passwords don't match!"
    },
    "video": {
        "videos": "Videos",
        "views": "{views} views",
        "watched": "Watched",
        "sponsor_segments": "Sponsors Segments",
        "ratings_disabled": "Ratings Disabled",
        "chapters": "Chapters",
        "live": "{0} Live",
        "shorts": "Shorts",
        "all": "All",
        "category": "Category",
        "license": "License",
        "visibility": "Visibility",
        "chapters_horizontal": "Horizontal",
        "chapters_vertical": "Vertical"
    },
    "search": {
        "did_you_mean": "Did you mean: {0}?",
        "all": "YouTube: All",
        "videos": "YouTube: Videos",
        "channels": "YouTube: Channels",
        "playlists": "YouTube: Playlists",
        "music_songs": "YT Music: Songs",
        "music_videos": "YT Music: Videos",
        "music_albums": "YT Music: Albums",
        "music_playlists": "YT Music: Playlists",
        "music_artists": "YT Music: Artists"
    },
    "subscriptions": {
        "subscribed_channels_count": "Subscribed to: {0}"
    },
    "info": {
        "preferences_note": "Note: preferences are saved in the local storage of your browser. Deleting your browser data will reset them.",
        "page_not_found": "Page not found",
        "copied": "Copied!",
        "cannot_copy": "Can't copy!",
        "local_storage": "This action requires localStorage, are cookies enabled?",
        "register_no_email_note": "Using an e-mail as username is not recommended. Proceed anyways?",
        "next_video_countdown": "Playing next video in {0}s",
        "hours": "{amount} hour(s)",
        "days": "{amount} day(s)",
        "weeks": "{amount} week(s)",
        "months": "{amount} month(s)",
        "register_note": "Register an account for this Piped instance. This will allow you to sync your subscriptions and playlists with your account, so they're stored on the server side. You can use all features without an account, but all data will be stored in your browser's local cache. Please make sure you do NOT use an email address as your username and choose a secure password that you do not use elsewhere.",
        "login_note": "Log in with an account created on this instance."
    }
}
